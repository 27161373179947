<template>
  <div id="laos">
    <div class="content_box">
      <div class="inner_max">
        <div class="form_deposit">
          <h4>{{ $t('deposit.laos.header') }}</h4>
          <div class="content">
            <div class="info_box">
              <img src="@/assets/images/channel/local_transfer.png" alt="" />
              <p>{{ $t('deposit.method.desc', { method: $t('deposit.laos.header') }) }}</p>
              <ul>
                <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
                <li>{{ $t('deposit.method.inst2', { number: '2', country: $t('deposit.laos.country') }) }}</li>
                <li>{{ $t('deposit.method.inst3', { number: '3' }) }}</li>
              </ul>
            </div>
            <div class="form_main">
              <p class="title">{{ $t('deposit.method.form.header', { method: $t('deposit.laos.header') }) }}</p>
              <el-form label-position="top" :model="form" ref="form" status-icon :rules="rules">
                <div class="form_box">
                  <ul class="clearfix">
                    <li class="fl">
                      <AccountNumber
                        supportedCurrencies="USD"
                        @setCurrency="setCurrency"
                        @setAccountNumber="setAccountNumber"
                      ></AccountNumber>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.amt')" prop="amount">
                        <numeric-input v-model="form.amount" :currency="accountCurrency" :precision="2"></numeric-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('common.field.imptNotes')">
                        <el-input v-model="form.notes" data-testid="applicationNotes"></el-input>
                      </el-form-item>
                    </li>
                    <li class="fr">
                      <p class="mb-2">
                        <span class="required_icon">*</span>
                        {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'LAK' }) }}
                        <span>{{ rate }}</span>
                      </p>
                      <p>
                        <span class="required_icon">*</span> LAK: <span>{{ rateChange }}</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
        <div v-html="bankHTML"></div>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import rounding from '@/util/rounding';
import AccountNumber from '@/components/form/AccountNumber';
import mixin from '@/mixins/page/deposit';
import { autoForm } from '@/util/getAutoForm';
import { apiQueryRate_USD_LAK, apiCpsDepositH5_payment } from '@/resource';

export default {
  name: 'Laos',
  components: { NumericInput, AccountNumber },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (this.rateChange > this.maxLimit) {
        callback(
          new Error(this.$t('common.formValidation.amtLess', { maxLimit: this.maxUSD, currency: this.accountCurrency }))
        );
      } else {
        callback();
      }
    };
    return {
      form: {
        accountNumber: '',
        amount: '',
        notes: ''
      },
      rules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      },
      rate: 0.0,
      bankHTML: '',
      maxLimit: 150000000
    };
  },
  computed: {
    rateChange() {
      return rounding(Math.ceil, this.rate * this.form.amount, 2);
    },
    maxUSD() {
      return rounding(Math.floor, this.maxLimit / this.rate, 2);
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.form.accountNumber = accountNumber;
    },
    success(msg) {
      this.bankHTML = autoForm(msg);
      if (this.bankHTML.length > 0) {
        this.$nextTick(function () {
          if (document.getElementById('AutoForm')) {
            document.getElementById('AutoForm').submit();
          } else {
            this.loading = false;
            this.errorMessage(this.$t('deposit.default.failed'));
          }
        });
      } else {
        this.loading = false;
        this.errorMessage(this.$t('deposit.default.failed'));
      }
    },
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              this.depositSubmitWithRate(result, this.success);
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiCpsDepositH5_payment(
        {
          mt4Account: this.form.accountNumber,
          operateAmount: this.form.amount,
          applicationNotes: this.form.notes,
          depositAmount: this.rateChange,
          rate: this.rate
        },
        this.token
      );
    },
    queryRate() {
      apiQueryRate_USD_LAK()
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data;
        })
        .catch(err => {
          this.$t('deposit.default.rate.result.rateError', { oldCurrency: 'USD', newCurrency: 'LAK' });
        });
    }
  },
  mounted() {
    this.queryRate();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
